import React, { useEffect, useState, useContext, useRef } from 'react';
import { AiOutlineFileDone } from "react-icons/ai";
import { db } from '../../Libs/firebase';
import * as firestore from "firebase/firestore";
import * as Constants from "../../constants";
import * as Components from "../../Components";
import * as Context from "../../Context";

const Home = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [assignedTo, setAssignedTo] = useState('all');
  const [selectedService, setSelectedService] = useState(Constants.MAIN_SERVICES[0].key);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastLead, setLastLead] = useState(null);
  const leadContainerRef = useRef(null);
  const { user } = useContext(Context.UserContext);

  useEffect(() => {
    const preventBackNavigation = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener('popstate', preventBackNavigation);
    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, []);

  useEffect(() => {
    const scrollContainer = leadContainerRef.current;
    scrollContainer.scrollTop = 0;
    setSelectedLead(null);
    getRealtimeLeads(true);
  }, [selectedService, searchText, filterStatus, assignedTo]);

  const getLeadsQuery = () => {
    const collectionRef = firestore.collection(db, Constants.FC_INSURANCE_LEADS);

    let q = firestore.query(
      collectionRef,
      firestore.orderBy('created_at', 'desc'),
      firestore.limit(10)
    );

    if (selectedService && selectedService !== 'all') {
      q = firestore.query(q, firestore.where('service_type', '==', selectedService));
    }

    if (filterStatus && filterStatus !== 'all') {
      q = firestore.query(q, firestore.where('status', '==', filterStatus));
    }

    if (searchText) {
      q = firestore.query(q, firestore.where('mobile_no', '==', searchText));
    }

    // If Admin show all leads else show only leads assigned to user
    if (user.role === Constants.USER_GROUPS.ADMIN) {
      if (assignedTo !== 'all') {
        q = firestore.query(q, firestore.where('assigned_to', '==', assignedTo));
      }
    } else {
      const current_user = user.email;
      q = firestore.query(q, firestore.where('assigned_to', '==', current_user));
    }
    return q
  };

  const getMoreLeads = async () => {
    if (isLoading) return; // Prevent multiple concurrent requests
    setIsLoading(true);

    try {
      let q = getLeadsQuery()
      if (lastLead) {
        q = firestore.query(q, firestore.startAfter(lastLead));
        const querySnapshot = await firestore.getDocs(q)
        const documents = querySnapshot.docs.map((doc) => {
          let _data = doc.data();
          _data['id'] = doc.id;
          return _data;
        });
        setIsLoading(false);
        const _lastLead = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastLead(_lastLead);
        setLeads((prevLeads) => [...prevLeads, ...documents]);
      }
    } catch (error) {
      console.log("Error fetching leads....", error);
    } finally {
      setIsLoading(false);
    }
  }

  const getRealtimeLeads = async () => {
    if (isLoading) return; // Prevent multiple concurrent requests
    setIsLoading(true);

    try {
      let q = getLeadsQuery()
      return firestore.onSnapshot(q, (querySnapshot) => {
        const documents = querySnapshot.docs.map((doc) => {
          let _data = doc.data();
          _data['id'] = doc.id;
          return _data;
        });
        setIsLoading(false);
        const _lastLead = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLeads(documents);
        setLastLead(_lastLead);
      });
    } catch (error) {
      console.log("Error fetching leads....", error);
    } finally {
      setIsLoading(false);
    }
  }

  // Attach scroll event listener to fetch more items when reaching the end of the scroll container
  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = leadContainerRef.current;
      if (!scrollContainer) return;

      const isAtBottom =
        scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 1;
      if (isAtBottom) {
        getMoreLeads();
      }
    };

    const scrollContainer = leadContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [lastLead]);

  const handleLeadSelect = (lead) => {
    setSelectedLead(lead);
  };

  const renderLeadDetails = () => {
    if (selectedLead) {
      return (
        <div className='overflow-y-scroll h-[90%] p-4 pb-20 lg:pb-10'>
          <Components.LeadDetails leadDetails={selectedLead} />
        </div>
      );
    } else {
      return (
        <div className='flex justify-center items-center h-[50vh] gap-2'>
          <p className='text-gray-600 text-center'>
            Please select a lead to see details!
          </p>
        </div>
      );
    }
  };

  const renderLeads = () => {
    if (leads.length > 0) {
      return (
        <div className='flex flex-col gap-3 pr-2'>
          {leads.map((lead, idx) => (
            <Components.LeadSummaryRow
              key={idx}
              lead={lead}
              isSelected={lead.id === selectedLead?.id}
              onSelect={(_lead) => handleLeadSelect(_lead)}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className='mt-28'>
          <div className='flex justify-center items-center h-32 gap-2'>
            <AiOutlineFileDone size={40} className='text-gray-600' />
            <p className='text-gray-600 text-xl'>
              {isLoading ? "Please wait! Loading..." : "No enquiries yet!"}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='bg-white h-[76vh]'>
      <div className='flex justify-between items-center pb-6'>
        <div className='w-[300px]'>
          <Components.SearchBox
            placeholder='Search by mobile number'
            onChange={(value) => setSearchText(value)}
            className="w-full"
            type='number'
          />
        </div>
        <Components.MainServiceFilter
          onServiceChange={setSelectedService}
          onStatusChange={setFilterStatus}
          onAssignedToChange={setAssignedTo}
          selectedService={selectedService}
          filterStatus={filterStatus}
          assignedTo={assignedTo}
        />
      </div>
      <div className='flex h-[100%]'>
        <div className='w-full md:w-2/3 overflow-y-scroll h-[100%] pb-20 lg:pb-10' ref={leadContainerRef}>
          {renderLeads()}
        </div>
        <div className='w-full md:w-1/3 hidden md:block border rounded-r-md ml-1'>
          <div className="flex justify-between items-center text-xl font-semibold text-gray-700 p-4 shadow-sm">
            <div>Lead Details</div>
            <div className='text-sm'>
              <Components.LeadStatus lead={selectedLead} />
            </div>
          </div>
          {renderLeadDetails()}
        </div>
      </div>
      <div className='block md:hidden overflow-y-scroll'>
        {selectedLead && (
          <Components.SlideOver title="Lead Details" onClose={() => setSelectedLead(null)}>
            <div className='pb-24 px-4 sm:px-6'>
              <Components.LeadDetails leadDetails={selectedLead} />
            </div>
            <div className='fixed bottom-0 h-16 shadow-md bg-gray-100 border-t w-full'>
              <div className='w-80 p-2 font-semibold text-gray-600'>
                <Components.LeadStatus
                  className="h-12 bg-white"
                  lead={selectedLead}
                />
              </div>
            </div>
          </Components.SlideOver>
        )}
      </div>
    </div>
  );
};

export default Home;
