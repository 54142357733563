import React, { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import * as firestore from "firebase/firestore";
import * as Utils from "../Utils";
import * as LeadServices from "../Services/insuranceLeads";
import * as Context from "../Context";

const AssignLead = ({ lead, onSuccess, onCancel }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { users } = useContext(Context.UserContext);
    const [error, setError] = useState('');

    const getInsuranceLabel = () => {
        return lead.insurance_type
            ? Utils.getInsuranceTypeDetailsByKey(lead.insurance_type)['title']
            : lead.service_policy_option
                ? lead.service_policy_option
                : 'Other'
    }

    const getServiceLabel = () => {
        return Utils.getServiceTypeDetailsByKey(lead.service_type)['title']
    }

    const onUserChange = (e) => {
        setError('');
        setSelectedUser(e.target.value)
    }

    const onSubmit = async () => {
        if (selectedUser) {
            setIsLoading(true);
            const _user = Utils.getUser();
            const fieldsToUpdate = {
                'assigned_to': selectedUser,
                'assigned_by': _user.email,
                'assigned_at': firestore.serverTimestamp()
            }
            const success = await LeadServices.updateLead(lead.id, fieldsToUpdate);
            if (success) {
                onSuccess(selectedUser);
            }
        } else {
            setError('Please select user a lead!');
        }
    }

    return (
        <Modal
            title="Assign Lead"
            onCancel={onCancel}
            onSubmit={onSubmit}
            submitTitle='Assign'
            show={!!lead}
            isLoading={isLoading}
        >
            <div className="pb-3 mb-5 text-gray-600">
                <div className="pb-5">
                    <div className="pb-2 text-sm">
                        Lead by {lead.name} on {Utils.getLocalDateTime(lead.created_at.seconds)}
                    </div>
                    <div className="flex gap-1">
                        <span className="bg-green-100 text-green-800 text-xs px-2.5 py-0.5 rounded">
                            {getServiceLabel()}
                        </span>
                        <span className="bg-blue-100 text-blue-800 text-xs px-2.5 py-0.5 rounded">
                            {getInsuranceLabel()}
                        </span>
                    </div>
                </div>
                <div>
                    <select
                        className={`block py-2.5 rounded-md w-full text-sm text-gray-600 bg-transparent  border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer`}
                        onChange={onUserChange}
                        value={selectedUser}
                    >
                        <option value='' selected disabled>Select user to assign lead</option>
                        {
                            users.map(user => <option value={user.email}>{user.name} ({user.email})</option>)
                        }
                    </select>
                    <p className="text-sm text-red-500 pt-1">{error}</p>
                </div>
            </div>
        </Modal>
    )
}
export default AssignLead;