import health_insurance from "./Assets/Images/health_insurance.webp";
import life_insurance from "./Assets/Images/life_insurance.webp";
import general_insurance from "./Assets/Images/general_insurance.webp";
import purchase_policy from "./Assets/Images/financial avtar.png";
import renew_policy from "./Assets/Images/laptop avtar.png";
import other_services from "./Assets/Images/graph avtar.png";
import claim_policy from "./Assets/Images/paper avtar.png";
import kamal_bansal from "./Assets/Images/kamal_bansal.jpeg";
import deepak_bansal from "./Assets/Images/deepak_bansal.png";
import * as routes from "./routes";

export const FIELDS_TO_IGNORE_FROM_CLEANUP = ['service_type', 'insurance_type']

export const INSURANCE_TYPES_ENUM = {
    HEALTH_INSURANCE: "health_insurance",
    LIFE_INSURANCE: "life_insurance",
    VEHICLE_INSURANCE: "car_insurance",
    BUSINESS_INSURANCE: "business_insurance",
    GENERAL_INSURANCE: "general_insurance"
}

export const MAIN_SERVICES_ENUM = {
    POLICY_PURCHASE: "policy_purchase",
    POLICY_RENEW: "policy_renew",
    OTHER_SERVICES: "other_services",
    POLICY_CLAIM: "policy_claim",
}

export const MAIN_SERVICES = [
    {
        title: "Service Policy",
        admin_title: "Service Policy",
        short_admin_title: "Service",
        description: "Helping to maintain existing policies portfolio updated.",
        key: MAIN_SERVICES_ENUM.POLICY_RENEW,
        image: renew_policy,
        link_to: routes.RenewPolicy,
        class_name: "",
    },
    {
        title: "Claim Policy",
        admin_title: "Policy Claim",
        short_admin_title: "Claim",
        description: "Facing challenges for your maturity claim money back or death claim.",
        key: MAIN_SERVICES_ENUM.POLICY_CLAIM,
        image: claim_policy,
        link_to: routes.ClaimPolicy,
        class_name: "",
    },
    {
        title: "Other Services",
        admin_title: "Other",
        short_admin_title: "Other",
        description: "Missing Shares, D-mat of shares, Investment options and much more.",
        key: MAIN_SERVICES_ENUM.OTHER_SERVICES,
        image: other_services,
        link_to: routes.OtherServices,
        class_name: "",
    },
    {
        title: "Policy Advise",
        admin_title: "Policy Purchase",
        short_admin_title: "New",
        description: "A healthy discussion will help you to select the right plan for you.",
        key: MAIN_SERVICES_ENUM.POLICY_PURCHASE,
        image: purchase_policy,
        link_to: routes.PurchasePolicy,
        class_name: "",
    }
]

export const INSURANCE_DETAILS = [
    {
        title: "Health Insurance",
        key: INSURANCE_TYPES_ENUM.HEALTH_INSURANCE,
        short_name: "Health",
        description: "Financial security for medical expenses, providing coverage for healthcare services and treatments.",
        image: health_insurance,
    },
    {
        title: "Life Insurance",
        key: INSURANCE_TYPES_ENUM.LIFE_INSURANCE,
        short_name: "Life",
        description: "Assurance for your loved ones' financial well-being in the event of your death, offering a payout or income replacement.",
        image: life_insurance,
    },
    {
        title: "General Insurance",
        key: INSURANCE_TYPES_ENUM.GENERAL_INSURANCE,
        short_name: "General",
        description: "General insurance provides coverage for a wide range of risks and liabilities, including business, vehicle protection against various unforeseen events.",
        image: general_insurance,
    }
]

export const BUSINESS_INSURANCE_TYPES = [
    "General Liability Insurance",
    "Property Insurance",
    "Professional Liability Insurance",
    "Workers' Compensation Insurance",
    "Business Interruption Insurance",
    "Commercial Auto Insurance",
    "Cyber Liability Insurance",
    "Directors and Officers (D&O) Insurance",
    "Product Liability Insurance",
    "Employment Practices Liability Insurance (EPLI)",
]

export const OTHER_SERVICES = [
    "Finding lost shares",
    "Demat of share",
    "Mutual fund portfolio",
    "Investment in other financial products",
]

export const SERVICE_EXISTING_POLICY_OPTIONS = [
    "Generation & maintenance of life insurance portfolio",
    "Revival of lapsed polices",
    "Online registration of all policies",
    "Fresh/ change of nominee and successive nomination",
    "Assignment/Re-assignment of policies",
    "Assistance to pay premium",
    "Registration of Bank/ Mobile / Email details",
    "Assistance in case death claim settlement",
    "Assistance in money back & maturity payment",
    "Assistance in loan on LIC policies",
    "Change of address in policies",
    "Duplicate policy, if lost",
]

export const RISK_COVER_OPTIONS = [
    "1 to 3 Lakhs",
    "5 to 6 Lakhs",
    "7 to 10 Lakhs",
    "More than 10 Lakhs",
]

export const GENERAL_INSURANCE_TYPES = [
    "Business",
    "Vehicle",
    "Other"
]

export const USER_GROUPS = {
    ADMIN: 'Admin',
    EMPLOYEE: 'Employee',
    USER: 'User'
}

export const LEAD_STATUSES = {
    OPEN: 'Open',
    CONTACTED: 'Contacted',
    QUALIFIED: 'Qualified',
    UNDER_REVIEW: 'Under Review',
    FOLLOW_UP: 'Follow Up',
    UNRESPONSIVE: 'Not Responding',
    CANCELLED: 'Cancelled',
}

export const AGENT_ENQUIRY_STATUSES = {
    NEW: 'New',
    JOINED: 'Joined',
    RESIGNED: 'Resigned',
}

// Firebase collections
export const FC_INSURANCE_LEADS = "insuranceLeads";
export const FC_USERS = "users";
export const FC_AGENT_LEADS = "agentLeads";


// Our Team
export const OUR_TEAM = [
    {
        name: 'Deepak Bansal',
        position: 'Co-Founder',
        about: '20 years of experience, Insurance and Investments',
        photo: deepak_bansal,
        social_media: [
            // {
            //     icon: <FiSmartphone size="20"/>,
            //     link: 'tel: +919623299399',
            // }
        ]
    },
    {
        name: 'Kamal Bansal',
        position: 'Co-Founder',
        about: '20 years of Experience, MBA in Finance',
        photo: kamal_bansal,
        social_media: [

        ]
    }
]

// Statistic Counter

export const STATISTIC_COUNTER = [
    {
        text: "Years of experience",
        value: "25+"
    },
    {
        text: "Claims settled",
        value: "1K+"
    },
    {
        text: "Risk Covered",
        value: "100 CR+"
    },
    {
        text: "Customers",
        value: "1k+"
    }
]

export const WHY_US = [
    {
        title: "Personalized Service",
        description: "Our approach is not one-size-fits-all. We work closely with you to understand your specific circumstances and create a customized insurance plan that suits your requirements and budget."
    },
    {
        title: "Claims Support",
        description: "In times of need, we are here for you. Our claims process is hassle-free and efficient, providing you with the support you need when you need it most."
    },
    {
        title: "Our Team",
        description: "Meet the dedicated professionals behind InsureBaba. Our team brings a wealth of experience and a passion for helping our clients secure their futures."
    }
]

export const OUR_VALUES = [
    {
        title: "Customer-Centric",
        description: "You are at the heart of everything we do. We are committed to understanding your needs and delivering personalized insurance solutions that safeguard your financial future."
    },
    {
        title: "Integrity",
        description: "We believe in honesty and transparency. You can trust us to provide straightforward advice and ethical business practices."
    },
    {
        title: "Expertise",
        description: "Our team of experienced insurance professionals possesses in-depth knowledge of the industry. We stay updated on the latest trends and regulations to offer you the best advice."
    }
]