import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import * as Constants from "../constants";
import * as Components from "../Components";
import * as LeadService from "../Services/insuranceLeads";


const LeadStatus = ({ lead, className }) => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [statusComment, setStatusComment] = useState('');

    const onStatusChange = (e) => {
        const value = e.target.value;
        setNewStatus(value);
        setShowModal(true);
        setStatusComment('');
    }

    const onStatusChangeCancel = () => {
        setNewStatus('');
        setShowModal(false);
        setStatusComment('');
    }

    const updateLeadStatus = () => {
        const fields = {
            status: newStatus,
            comments: statusComment,
        }
        LeadService.updateLead(lead.id, fields).then((success) => {
            if (success) {
                toast.success(`Status updated successfully to ${newStatus}!`);
                setSelectedStatus(newStatus);
                setNewStatus('');
                setShowModal(false);
                setStatusComment('');
            }
        })
    }

    useEffect(() => {
        if (lead && Object.keys(lead).includes('status')) {
            setSelectedStatus(lead.status);
        } else {
            setSelectedStatus('');
        }
    }, [lead])


    if (!lead) return;

    return (
        <>
            <select
                className={`block rounded-md w-full text-sm text-gray-800 bg-transparent border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 ${className}`}
                onChange={(e) => onStatusChange(e, lead)}
                value={selectedStatus}
            >
                <option value='' selected disabled>Select status</option>
                {
                    Object.entries(Constants.LEAD_STATUSES).map(
                        ([key, value]) => <option value={value}>{value}</option>
                    )
                }
            </select>
            <div>
                <Components.Modal
                    onSubmit={updateLeadStatus}
                    onCancel={onStatusChangeCancel}
                    title="Change Lead Status"
                    submitTitle="Update Status"
                    show={showModal}
                >
                    <div className="flex flex-col gap-3 py-3">
                        <div className="flex items-center gap-2 text-sm">
                            <span className="bg-purple-100 font-semibold text-purple-800 text-xs px-2.5 py-1 rounded">
                                {selectedStatus}
                            </span>
                            <BsArrowRight size={23} className="text-gray-500" />
                            <span className="bg-green-100 font-semibold text-green-800 text-xs px-2.5 py-1 rounded">
                                {newStatus}
                            </span>
                        </div>
                        <div>
                            <label for="message" className="block mb-2 text-sm font-medium text-gray-700">
                                Your comments
                            </label>
                            <textarea
                                id="message"
                                rows="2"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Add your comments here..."
                                value={statusComment}
                                onChange={(e) => setStatusComment(e.target.value)}
                            >
                            </textarea>
                        </div>
                    </div>
                </Components.Modal>
            </div>
        </>
    )
}
export default LeadStatus;