import React from "react";
import { Header, Footer } from "..";
import { Outlet } from "react-router-dom";
import bg from "../../Assets/Images/abstract_background.webp";
import * as Context from "../../Context";

const UserLayout = () => {
  return (
    <Context.UserContextProvider>
      <div>
        <Header />
        <main className="w-full mt-32" style={{ backgroundImage: `url(${bg})` }}>
          <div style={{ backgroundColor: '#ffffff5c' }} className="min-h-[100vh]">
            <Outlet />
          </div>
        </main>
        <Footer />
      </div>
    </Context.UserContextProvider>
  );
};

export default UserLayout;
