import React from 'react';

const QuestionContainer = ({ message, isRequired, avatar = null }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      {avatar && <img className="w-36" src={avatar} alt='avatar' />}
      <div className="text-center ">
        <p className="text-lg break-word text-center w-fit text-gray-800 font-bold">
          {message}
        </p>
        {!isRequired && <p className="font-semibold text-orange-400">(Optional)</p>}
      </div>
    </div>
  );
};

export default QuestionContainer;
