const INSURANCE_CLAIM_FORM_CONFIG = [
    {
        field: "policies_details",
        dependsOn: [],
        isRequired: false,
        isLast: false,
        order: 2,
    },
    {
        field: "text_input",
        dependsOn: [],
        isRequired: false,
        isLast: false,
        order: 3,
    },
    {
        field: "mobile_no",
        isRequired: true,
        dependsOn: [],
        isLast: true,
        order: 1,
    }

    /**
     * Todo: Bug - gotoNextStep fails if last step is depends on other fields 
     * but value of others fields is not defined
     */
    // {
    //     field: "email",
    //     dependsOn: [],
    //     isRequired: false,
    //     isLast: true,
    //     order: 14,
    // }
]

export default INSURANCE_CLAIM_FORM_CONFIG;