import React, { useState } from "react";
import { addNewLead } from "../Services/agentLeads";
import { toast } from "react-hot-toast";
import send_email_notification from "../Libs/brevo";
import * as Avatars from "../Assets/Avatars";

const BecomeAnAgent = () => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    "Mobile No": "",
    Email: "",
    City: "",
  });

  const [error, setError] = useState({
    Name: "",
    "Mobile No": "",
    Email: "",
    City: "",
  });

  const onFieldValueChange = (e) => {
    const target = e.target;
    setFormData({ ...formData, [target.name]: target.value });
    validate();
  };

  const validate = () => {
    const _errors = { ...error };
    Object.keys(formData).map((field_key) => {
      if (!formData[field_key]) {
        _errors[field_key] = `${field_key} is required!`;
      } else {
        _errors[field_key] = "";
      }
    });
    setError(_errors);
    return !Object.values(_errors).some((value) => value !== "");
  };

  const submitForm = () => {
    const isValid = validate();
    if (isValid) {
      setIsLoading(true);
      addNewLead(formData).then(async (resp) => {
        console.log(resp);
        if (resp.error) {
          toast.error("Something went wrong! Please try again.")
        } else {
          const subject = `Become an Associate Inquiry`
          const html_body = `
            <html>
                <head></head>
                <body>
                    <p>
                        You have received new  interest to become an Associate by <b>${formData['Name']}</b>. <br>
                        Please visit https://insurebaba.com/admin/agents to see more details.<br/>
                        <br/>
                        Reference Id: ${resp?.data?.id}
                    </p>
                </body>
            </html>
          `;
          await send_email_notification(subject, html_body);
          setSuccess(true);
          setIsLoading(false);
        }
      });
    }
  };

  const SuccessMessage = () => {
    return (
      <div className="flex flex-col items-center py-5 text-center w-full gap-4">
        <img src={Avatars.happy} width="120" alt="Thank you!" />
        <div className="text-2xl text-gray-700 font-semibold">Thank you!</div>
        <div>We have received your request. We will get back to you soon! </div>
      </div>
    );
  };

  const becomeAnAgentForm = () => {
    return (
      <div>
        <div className="text-gray-700">
          <h3 className="text-xl text-gray-800/90 font-semibold pb-1">
            Get associated with us
          </h3>
          Fill the following form to become an associate!!
        </div>
        <div className="pt-2">
          <div className="mb-3">
            <label
              for="name"
              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            >
              Your name
            </label>
            <input
              type="text"
              id="name"
              name="Name"
              value={formData["Name"]}
              onChange={onFieldValueChange}
              placeholder="e.g Samil Joil"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
            />
            {error["Name"] && (
              <p className="text-sm text-red-500 my-2">{error["Name"]}</p>
            )}
          </div>
          <div className="mb-3">
            <label
              for="city"
              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            >
              Your City
            </label>
            <input
              type="text"
              id="city"
              name="City"
              value={formData["City"]}
              onChange={onFieldValueChange}
              placeholder="e.g Pune"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
            />
            {error["City"] && (
              <p className="text-sm text-red-500 my-2">{error["City"]}</p>
            )}
          </div>
          <div className="mb-3">
            <label
              for="mobile_no"
              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            >
              Your mobile number
            </label>
            <input
              type="number"
              id="mobile_no"
              name="Mobile No"
              value={formData["Mobile No"]}
              onChange={onFieldValueChange}
              placeholder="e.g 9699999999"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
            />
            {error["Mobile No"] && (
              <p className="text-sm text-red-500 my-2">{error["Mobile No"]}</p>
            )}
          </div>
          <div className="mb-3">
            <label
              for="email"
              className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="Email"
              value={formData["Email"]}
              onChange={onFieldValueChange}
              placeholder="e.g email@example.com"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5"
            />
            {error["Email"] && (
              <p className="text-sm text-red-500 my-2">{error["Email"]}</p>
            )}
          </div>
          <button
            onClick={submitForm}
            className="text-white bg-gray-800 disabled:bg-gray-500 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            disabled={isLoading}
          >
            {isLoading ? 'Please wait..' : 'Submit'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center gap-4 py-8 md:px-4 mx-auto sm:py-8 lg:px-6">
      <div className="flex flex-col justify-center items-center w-full md:w-2/3 lg:px-20">
        <img src={Avatars.puzzle} width={400} />
        <h2 className="mb-4 text-3xl font-extrabold text-gray-900 text-center">
          Let's find out what brings us together.
        </h2>
        <p className="mb-8 font-light text-gray-800 text-center text-xl font-semibold">
          Professional service providers are typically experts in their
          respective fields. They bring specialized knowledge, skills, and
          experience to the table, which can be invaluable for clients seeking
          solutions to complex problems or seeking to optimize their operations.
          Connect with us to be a part of this journey.
        </p>
      </div>
      <div className="w-full max-w-[400px] lg:w-1/3 flex flex-col px-8 pt-8 pb-10 bg-white rounded-lg">
        {success ? <SuccessMessage /> : becomeAnAgentForm()}
      </div>
    </div>
  );
};
export default BecomeAnAgent;
