import React, { useContext } from "react";
import * as Constants from "../constants";
import * as Context from "../Context";

const MainServiceFilter = ({ selectedService, filterStatus, assignedTo, onServiceChange, onStatusChange, onAssignedToChange }) => {
    const { user, users } = useContext(Context.UserContext);

    const statusFilterSelect = () => {
        return <select
            onChange={(e) => onStatusChange(e.target.value)}
            id="status"
            value={filterStatus}
            className="border-slate-200 text-gray-900 text-sm font-medium lg:rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-3 md:p-2.5 md:pr-10"
        >
            <option value='' className="text-center" disabled>
                Status Filter
            </option>

            <option value='all' className="text-center">
                Show All
            </option>
            {
                Object.values(Constants.LEAD_STATUSES).map((status, idx) => {
                    return (
                        <option
                            key={idx}
                            type="button"
                            value={status}
                            className="text-center"
                        >
                            {status}
                        </option>
                    )
                })
            }
        </select>
    }

    const assignedToFilter = () => {
        return <select
            onChange={(e) => onAssignedToChange(e.target.value)}
            id="assignedTo"
            value={assignedTo}
            className="border-slate-200 text-gray-900 text-sm font-medium lg:rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-8"
        >
            <option value='' className="text-center" disabled>
                Filter by Assigned To
            </option>

            <option value='all' className="text-center">
                Assigned to All
            </option>
            {users.map((user, idx) =>
                <option
                    key={idx}
                    type="button"
                    value={user.email}
                    className="text-center"
                >
                    {user.name}
                </option>
            )}
        </select>
    }

    const serviceFilterSelect = () => {
        return <select
            onChange={(e) => onServiceChange(e.target.value)}
            id="services"
            value={selectedService}
            className="border-slate-200 text-gray-900 text-sm md:text-sm font-medium lg:rounded-md first-letter:focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
            <option value='' className="text-center" disabled>
                Filter by Service Type
            </option>

            <option value='all' className="text-center">
                Show All
            </option>
            {
                Constants.MAIN_SERVICES.map((service, idx) => {
                    return (
                        <option
                            key={idx}
                            type="button"
                            value={service.key}
                            className="text-center"
                        >
                            {service.admin_title}
                        </option>
                    )
                })
            }
        </select>
    }

    return (
        <div>
            <div className="hidden lg:flex lg:gap-2">
                {
                    user.role === Constants.USER_GROUPS.ADMIN
                    && <div className="w-[150px]">
                        {assignedToFilter()}
                    </div>
                }
                <div className="w-[160px]">
                    {statusFilterSelect()}
                </div>
                <div className="flex gap-2">
                    {
                        Constants.MAIN_SERVICES.map((service, idx) => {
                            const style = service.key === selectedService ? "border-gray-600 bg-black text-white" : "text-gray-800"
                            return (
                                <div
                                    key={idx}
                                    onClick={() => onServiceChange(service.key)}
                                    className={`flex gap-2 text-sm font-medium p-2 px-4 rounded-md border-2 cursor-pointer ${style}`}
                                >
                                    {/* <img src={service.image} width={20} className="bg-white rounded-full p-1" /> */}
                                    {service.admin_title}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="block lg:hidden mx-auto w-full">
                <div className="fixed z-50 w-full h-14 max-w-xl -translate-x-1/2 bg-white border border-gray-200 bottom-0 md:bottom-4 rounded-md left-1/2 shadow-md">
                    <div className="h-full max-w-xl flex mx-auto">
                        {statusFilterSelect()}
                        {serviceFilterSelect()}
                        {assignedToFilter()}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default MainServiceFilter;