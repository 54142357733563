import React from "react";
import { useLocation } from 'react-router-dom';
import logo from "../Assets/Images/logo.png";
import { BiLinkExternal } from "react-icons/bi";

const Footer = () => {
  const location = useLocation();
  const shouldHide = location.pathname.startsWith('/admin')

  if (shouldHide) return;

  return (
    <footer className="bg-[#192231]">
      <div className="mx-auto w-full max-w-screen-xl p-4 md:p-0 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 w-full md:w-[600px] pr-4">
            <a href="/" className="flex flex-col">
              <img src={logo} className="mr-3" width={300} alt="InsureBaba Logo" />
              {/* <p className="text-gray-300 py-3 text-justify">
                Insure Baba is a group of 150 Business Associates working together with vision to enhance the clients experience in the Insurance Industry. This was established in 1996 by Mr. Vinod Thole (Senior Business Associate – LIC of India).
              </p> */}
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 md:px-10">
            <div>
              <h2 className="mb-6 text-base font-semibold uppercase text-white">Resources</h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="/" className="mr-4 hover:underline md:mr-6 ">Home</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 font-semibold uppercase text-white">Our Services</h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-2">
                  <a href="/purchase" className="hover:underline ">Purchase Policy</a>
                </li>
                <li className="mb-2">
                  <a href="/renew" className="hover:underline ">Service Policy</a>
                </li>
                <li className="mb-2">
                  <a href="/claim" className="hover:underline ">Claim Policy</a>
                </li>
                <li className="mb-2">
                  <a href="/other" className="hover:underline ">Other Services</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 font-semibold uppercase text-white">Contact Us</h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-2">
                  <p className="font-semibold text-gray-200">Phone No </p>
                  <p>91300 45678</p>
                </li>
                <li className="mb-2">
                  <p className="font-semibold text-gray-200">Address </p>
                  <p>Office No. 05, Sadguru Krupa Heights, Salisbury Park, Gultekadi, Market Yard, Pune Maharashtra. </p>
                </li>
                <li className="mb-2">
                  <p className="font-semibold text-gray-200">Email </p>
                  info@insurebaba.com
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 sm:mx-auto border-gray-700 lg:my-8" />
        <div className="flex flex-col justify-center items-center text-gray-400 ">
          <div>
            <span className="flex sm:text-center gap-1">
              &copy; 2023
              <a href="/" className="hover:underline">
                InsureBaba
              </a>. All Rights Reserved.
            </span>
          </div>
          <div>
            <a href="https://techbsoftwares.com" target="_blank" className="flex" rel="noreferrer">
              Design and developed by
              <span className="text-orange-500 pl-1 flex gap-1">
                TechB Softwares <BiLinkExternal />
              </span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
