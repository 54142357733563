import React from "react";
import * as Components from "../Components";
import * as FormConfig from "../FormConfig";
import * as avatars from "../Assets/Avatars";
import * as Constants from "../constants";

const ServicePolicy = () => {
  const formFields = FormConfig.RENEW_FORM_CONFIG;
  const service_type = Constants.MAIN_SERVICES_ENUM.POLICY_RENEW;

  return (
    <div className="container mx-auto px-2">
      <div className="relative before:absolute before:top-0 before:left-1/2">
        <div className="lg:flex lg:items-center max-w-[85rem] mx-auto px-2 lg:px-8 pt-10 pb-10 min-h-[90vh]">
          <Components.HeadingHero
            part1Title="Policy"
            part2Title="Service"
            description="Policy renewal: Your key to ongoing protection and peace of mind. It ensures your coverage remains relevant, tailored, and confidently aligned with your evolving needs."
            colorReverse={true}
            avatar={avatars.laptop}
          />
          <Components.ServiceFormContainer
            formFields={formFields}
            service_type={service_type}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicePolicy;
