import * as FormFields from "./Components/FormFields";

const FORM_FIELDS = {
    'insurance_type': {
        title: 'Insurance Type',
        component: FormFields.InsuranceType
    },
    'name': {
        title: 'Name',
        component: FormFields.NameField
    },
    'mobile_no': {
        title: 'Mobile No',
        component: FormFields.MobileField
    },
    'email': {
        title: 'Email ID',
        component: FormFields.EmailField
    },
    'dob': {
        title: 'Date of Birth',
        component: FormFields.DOB
    },
    'family_individual': {
        title: 'Family or Individual',
        component: FormFields.IndividualVsFamily
    },
    'life_insurance_type': {
        title: 'Life Insurance Type',
        component: FormFields.LifeInsuranceType
    },
    'general_insurance_type': {
        title: 'General Insurance Type',
        component: FormFields.GeneralInsuranceTypes
    },
    'business_insurance_type': {
        title: 'Business Insurance Type',
        component: FormFields.BusinessInsuranceTypes
    },
    'member_details': {
        title: 'Member Details',
        component: FormFields.MemberDetails
    },
    'previous_policies': {
        title: 'Old Policies',
        component: FormFields.PolicyDetailsField
    },
    'policies_details': {
        title: 'Old Policies',
        component: FormFields.PolicyDetailsField
    },
    'risk_cover': {
        title: 'Risk Cover',
        component: FormFields.RiskCover
    },
    'text_input': {
        title: 'Other Comments',
        component: FormFields.TextInput
    },
    'have_disease': {
        title: 'Have Existing Disease?',
        component: FormFields.HaveDisease
    },
    'members_count': {
        title: 'No. of Members to Cover',
        component: FormFields.FamilyMemberCount
    },
    'other_service': {
        title: 'Requested Services',
        component: FormFields.OtherService
    },
    'service_policy_option':{
        title: 'Required Service',
        component: FormFields.ServicePolicyOptions,
    },
    'related_documents': {
        title: 'Related Documents',
        component: FormFields.RelatedDocuments,
    },
    'policy_file': {
        title: 'Policy File',
        component: null,
    },
    'policy_number': {
        title: 'Policy Number',
        component: null
    },
    'relation': {
        title: 'Relationship',
        component: null
    },
    'assigned_to': {
        title: 'Assigned To',
        component: null,
    },
    'assigned_by': {
        title: 'Assigned By',
        component: null,
    },
    'service_type': {
        title: 'Service Type',
        component: null,
    },
    'comments': {
        title: 'Comments',
        component: null,
    }
}

export default FORM_FIELDS;