import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const FamilyMemberCount = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");
    const familyMembersCount = ["1", "2", "3", "4", "5", "6"]

    const validate = (inputValue) => {
        if (!inputValue && required) {
            return "Please select no. of members!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[300px] md:w-[400px] flex flex-col items-center justify-center">
            <ChatMessage message="How many members would you like to get insured?" isRequired={required} />
            <div className="gap-3 mt-3">
                <div className="flex items-center align-middle w-full">
                    <select
                        onChange={onChange}
                        value={value}
                        className={`${classNames} block text-gray-900 text-sm appearance-none w-full px-4 py-2 pr-8 rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                    >
                        <option value="" disabled selected>How many member to cover?</option>
                        {familyMembersCount.map(count => (
                            <option key={count} value={count}>{count}</option>
                        ))}
                    </select>
                </div>
                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    )
}
export default FamilyMemberCount;