import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const LifeInsuranceType = ({ value = "", required = true, isValid, onValueChange, formData }) => {
    const [error, setError] = useState("");
    const LifeInsuranceTypes = [
        "Single Premium Policy",
        "ULIP Policy",
        "Endorsement Policy",
        "Term Insurance",
        "Need advice"
    ]
    const validate = (inputValue) => {
        if (!inputValue && required) {
            return "Please select insurance type";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")

    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[300px] md:w-[400px] flex flex-col items-center justify-center">
            <ChatMessage message="Which type of Life insurance you want?" isRequired={required} />
            <div className="mt-5">
                <select
                    onChange={onChange}
                    value={value}
                    className={`${classNames} block text-gray-900 text-sm appearance-none w-full py-2.5 mt-3 rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                >
                    <option value="" disabled selected>Select Life Insurance Type</option>
                    {LifeInsuranceTypes.map(_option => {
                       return <option key={_option} value={_option}>{_option}</option>
                    })}
                </select>
            </div>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default LifeInsuranceType;