import React, { useState, useEffect } from "react";
import { MdInstallMobile } from "react-icons/md";
import * as hooks from "../Hooks";

let deferredPrompt;

const InstallApp = () => {
    const [installable, setInstallable] = useState(false);
    const isScrolledToBottom = hooks.useScroll();

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            deferredPrompt = e;
            setInstallable(true);
        });

        window.addEventListener('appinstalled', () => {
            console.log('INSTALL: Success');
        });
    }, []);

    const handleInstallClick = (e) => {
        setInstallable(false);
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
        });
    };

    return (
        <>
            {
                installable &&
                <div className={`fixed right-4 ${isScrolledToBottom ? 'bottom-32' : 'bottom-20'} rounded-md border border-gray-500 bg-white z-50 shadow-md`}>
                    <div className="flex gap-2 p-2 px-4 text-sm">
                        <MdInstallMobile size={20} />
                        <button onClick={handleInstallClick}>
                            Install App
                        </button>
                    </div>
                </div>
            }
        </>
    )
}
export default InstallApp;