import React, { useContext } from "react";
import { AdminHeader, Footer, LoginPanel, InternetStatus } from "..";
import { Outlet, Navigate } from "react-router-dom";
import * as Context from "../../Context";
import * as Constants from "../../constants";
import * as Routes from "../../routes";

const UserLayout = () => {
    const { user } = useContext(Context.UserContext);

    const isEmployee = [
        Constants.USER_GROUPS.ADMIN,
        Constants.USER_GROUPS.EMPLOYEE
    ].includes(user?.role);
    
    if (user && !isEmployee) {
        return <Navigate to={Routes.Home} replace={true}/>
    }

    return (
        <div>
            <AdminHeader />
            <main className="bg-cover w-full mt-16 md:mt-24">
                <div style={{ backgroundColor: '#ffffff5c' }} className="min-h-[87vh]">
                    <div className="2xl:container mx-auto px-4 py-4">
                        <InternetStatus>
                            {
                                user
                                    ? <Outlet />
                                    : (
                                        <div className="flex  justify-center items-center mt-64">
                                            <LoginPanel />
                                        </div>
                                    )
                            }
                        </InternetStatus>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default UserLayout;
