import React from "react";
import * as Constant from "../constants";

const OurTeam = () => {
  return (
    <section className="">
      <div className="p-8 mx-auto max-w-screen-xl md:py-16">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            Our Team
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-8">
          {Constant.OUR_TEAM.map((member) => {
            return (
              <div className="items-center border border-gray-500 bg-gray-50 rounded-lg shadow sm:flex lg:w-1/2">
                <div>
                  <img
                    className="w-full md:w-48 rounded-lg sm:rounded-none sm:rounded-l-lg"
                    src={member.photo}
                    alt={`${member.name}'s Avatar`}
                  />
                </div>
                <div className="p-5">
                  <h3 className="text-2xl font-bold tracking-tight text-gray-900 ">
                    <p>{member.name}</p>
                  </h3>
                  <span className="text-xl">{member.position}</span>
                  <p className="mt-3 mb-4 text-[17px]">{member.about}</p>
                  <ul className="flex space-x-4 sm:mt-0">
                    {member.social_media.map((social) => {
                      return (
                        <li>
                          <a href={social.link} className="hover:text-gray-900">
                            {social.icon}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
