import React from "react";
import * as Constant from "../constants";

const AboutUs = () => {
    return (
        <div className="items-center py-8 px-4 lg:py-10 lg:px-6">
            <div className="gap-16 items-center max-w-screen-xl lg:grid lg:grid-cols-2  mx-auto ">
                <div className=" text-gray-800 text-lg md:text-xl">
                    <h2 className="mb-4 text-6xl md:text-7xl tracking-tight font-extrabold text-gray-900">
                        Who
                        <span className="bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent px-2">we</span>
                        are?
                    </h2>
                    <p className="text-justify mt-10 md:text-3xl">
                        At InsureBaba, we are dedicated to providing top-notch insurance solutions tailored  to meet your unique needs. With a deep commitment to professionalism, 
                        integrity,  and customer satisfaction, we have been serving individuals and businesses for 25+  years. Our mission is to protect what matters most to you, 
                        ensuring peace of mind in  an unpredictable world. 
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img className="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1" />
                    <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2" />
                </div>
            </div>
            <div className="flex justify-center items-center mt-14 px-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:gap-4 justify-between">
                    {
                        Constant.STATISTIC_COUNTER.map(counter => (
                            <div className="w-full bg-white/80 px-5 py-8 rounded-md shadow-md">
                                <div className="font-extrabold text-5xl  text-gray-800 text-center">{counter.value}</div>
                                <div className="font-bold text-3xl md:text-2xl lg:text-3xl text-gray-600 text-center">{counter.text}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutUs;