import React, { useEffect, useState } from 'react';
import { AiOutlineFileDone } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { db } from '../../Libs/firebase';
import { toast } from 'react-hot-toast';
import * as firestore from "firebase/firestore";
import * as Constants from "../../constants";
import * as agentServices from "../../Services/agentLeads";
import * as Utils from "../../Utils";

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const onStatusChange = (e, documentId) => {
    const value = e.target.value;
    agentServices.updateAgent(documentId, { status: value }).then(success => {
      if (success) {
        toast.success(`Status updated successfully to ${value}!`);
      } else {
        toast.error('Failed to update status! Try again later.')
      }
    })
  }

  const deleteAgent = (agentId) => {
    agentServices.deleteAgentById(agentId).then(success => {
      if (success) {
        toast.success('Agent deleted successfully!');
      } else {
        toast.error('Failed to delete agent! Try again later.');
      }
    })
  }

  const getRealtimeAgents = async () => {
    const collectionRef = firestore.collection(db, Constants.FC_AGENT_LEADS);
    let q = firestore.query(
      collectionRef,
      firestore.orderBy('created_at', 'desc')
    );

    return firestore.onSnapshot(q, (querySnapshot) => {
      const documents = querySnapshot.docs.map((doc) => {
        let _data = doc.data();
        _data['id'] = doc.id;
        return _data;
      });
      setIsLoading(false);
      setAgents(documents);
    });
  };

  useEffect(() => {
    const preventBackNavigation = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener('popstate', preventBackNavigation);
    return () => {
      window.removeEventListener('popstate', preventBackNavigation);
    };
  }, []);

  useEffect(() => {
    getRealtimeAgents()
  }, [])

  const renderAgents = () => {
    return (
      <div className="">
        <div className="py-4 mb-4 text-lg font-semibold text-left text-gray-900 bg-white">
          Manage Agents
          <p className="mt-1 text-base font-normal text-gray-500">
            This is a list of all agents who are now working with you, are interested in working with you, and are no longer working with you.
          </p>
        </div>
        <div className='overflow-x-scroll'>
          <table className="w-full text-sm text-left text-gray-500 border">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3">Agent Name</th>
                <th scope="col" className="px-3 py-3">Mobile No</th>
                <th scope="col" className="px-3 py-3">Email</th>
                <th scope="col" className="px-3 py-3">City</th>
                <th scope="col" className="px-3 py-3">Status</th>
                <th scope="col" className="px-3 py-3">Created At</th>
                <th scope="col" className="px-3 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                agents.map((agent, idx) => (
                  <tr className="border-b" key={agent.id}>
                    <th scope="row" className="px-3 py-3 font-medium text-gray-900 w-36">
                      <p className='w-36'>{agent['Name']}</p>
                    </th>
                    <td className="px-3 py-3 w-32">{agent['Mobile No']}</td>
                    <td className="px-3 py-3 w-56 md:break-all">{agent['Email']}</td>
                    <td className="px-3 py-3 w-36 md:break-all">
                      <p className='w-32'>{agent['City']?.charAt(0).toUpperCase() + agent['City']?.slice(1)}</p>
                    </td>
                    <td className="px-3 py-3 w-44">
                      <select
                        className={`block w-32 first-letter:rounded-md text-sm text-gray-800 bg-transparent border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200`}
                        onChange={(e) => onStatusChange(e, agent.id)}
                        value={agent['status']}
                      >
                        <option value='' selected disabled>Select status</option>
                        {
                          Object.entries(Constants.AGENT_ENQUIRY_STATUSES).map(
                            ([key, value]) => <option value={value}>{value}</option>
                          )
                        }
                      </select>
                    </td>
                    <td className="px-3 py-3 w-32">
                      <p className='w-32'>{Utils.getLocalDate(agent.created_at.seconds)}</p>
                    </td>
                    <td className="px-3 py-3 w-32">
                      <button
                        className="flex gap-1 px-2 py-1 text-xs bg-red-600/75 text-white h-8 rounded-md align-middle items-center"
                        onClick={() => deleteAgent(agent.id)}
                      >
                        <BiTrash size={15} />
                        <div>Delete</div>
                      </button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  };

  return (
    <div className='bg-white h-[76vh]'>
      <div className='flex h-[100%]'>
        <div className='w-full overflow-y-scroll h-[100%] pb-20'>
          {
            agents.length > 0 ?
              renderAgents()
              : (
                <div className='mt-28'>
                  <div className='flex justify-center items-center h-32 gap-2'>
                    <AiOutlineFileDone size={40} className='text-gray-600' />
                    <p className='text-gray-600 text-xl'>
                      {isLoading ? "Please wait! Loading..." : "No agents yet!"}
                    </p>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default Agents;
