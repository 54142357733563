import React, { useState } from "react";
import Modal from "./Modal";
import * as Utils from "../Utils";
import * as InsuranceLeadService from "../Services/insuranceLeads";
import { toast } from "react-hot-toast";

const DeleteLeadConfirm = ({ lead, onSuccess, onCancel }) => {
    const [isLoading, setIsLoading] = useState(false);

    const getInsuranceLabel = () => {
        return lead.insurance_type
            ? Utils.getInsuranceTypeDetailsByKey(lead.insurance_type)['title']
            : lead.service_policy_option
                ? lead.service_policy_option
                : 'Other'
    }

    const getServiceLabel = () => {
        return Utils.getServiceTypeDetailsByKey(lead.service_type)['title']
    }

    const onSubmit = async () => {
        setIsLoading(true);
        const success = await InsuranceLeadService.deleteLeadById(lead.id);
        if (success) {
            onSuccess();
            setIsLoading(false);
        } else {
            toast.error("Failed to delete lead! Please try after sometime.")
        }
    }

    return (
        <Modal
            title="Delete Lead"
            onCancel={onCancel}
            onSubmit={onSubmit}
            submitTitle='Delete'
            show={!!lead}
            isLoading={isLoading}
        >
            <div className="pb-3 mb-5 text-gray-600">
                <div className="pb-4">
                    <div className="pb-2 text-sm">
                        Lead by {lead.name} on {Utils.getLocalDateTime(lead.created_at.seconds)}
                    </div>
                    <div className="flex gap-1">
                        <span className="bg-green-100 text-green-800 text-xs px-2.5 py-0.5 rounded">
                            {getServiceLabel()}
                        </span>
                        <span className="bg-blue-100 text-blue-800 text-xs px-2.5 py-0.5 rounded">
                            {getInsuranceLabel()}
                        </span>
                    </div>
                </div>
                <p className="text-md text-red-500">
                    Are you sure you want to delete this lead?
                </p>
            </div>
        </Modal>
    )
}
export default DeleteLeadConfirm;