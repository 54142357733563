import React, { useEffect, useState } from "react";
import { BsCheckAll, BsPlusCircle } from "react-icons/bs";
import ChatMessage from "../QuestionContainer";
import FileUpload from "../FileUpload";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../Libs/firebase";

const PolicyDetailsField = ({ value = [], required = true, isValid, onValueChange, formData }) => {
    value = !value ? value : [{ policy_number: '', policy_file: '' }];

    const fileUploadPath = 'policies';
    const [error, setError] = useState("");
    const [policyDetails, setPolicyDetails] = useState(value);

    const validate = (inputValue) => {
        let _message = "";
        inputValue?.forEach(policy => {
            const policy_no = policy.policy_number;
            if (!policy_no && required) {
                _message = "All fields are required!";
            }
            else if (policy_no && policy_no.length < 7) {
                _message = "Policy number must be more than 6 digits!";
            }
        })
        return _message;
    }

    const onChange = (index, field, value) => {
        const updatedFields = [...policyDetails];
        updatedFields[index][field] = value;
        setPolicyDetails(updatedFields);
    };

    const handleAddField = () => {
        setPolicyDetails([...policyDetails, { policy_number: '', policy_file: '' }]);
    };

    const handleRemoveField = (index) => {
        const updatedFields = [...policyDetails];
        const policy_file = updatedFields[index]['policy_file'];

        deleteFileFromFirebase(policy_file).then(() => {
            updatedFields.splice(index, 1);
            setPolicyDetails(updatedFields);
        })
       
    };

    const handleRemoveFile = (index) => {
        const updatedFields = [...policyDetails];
        const policy_file = updatedFields[index]['policy_file'];
        if (!policy_file) return;

        deleteFileFromFirebase(policy_file).then(() => {
            updatedFields[index]['policy_file'] = '';
            setPolicyDetails(updatedFields);
        })
    }

    const deleteFileFromFirebase = async (filePath) => {
        if(!filePath) return;
        const fileRef = ref(storage, filePath);
        try {
            await deleteObject(fileRef);
        } catch (error) {
            console.log('Error deleting file...!');
        }
    }

    useEffect(() => {
        const errorMsg = validate(policyDetails);
        setError(errorMsg);
        errorMsg ? isValid(false) : isValid(true);
        onValueChange(policyDetails);
    }, [policyDetails])

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[300px] md:w-[400px] flex flex-col items-center justify-center">
            <ChatMessage message="Provide policy details" isRequired={required} avatar="" />
            <div className="w-full mt-4 mb-2  md:w-[350px]">
                <p className="text-xs text-red-400 text-center">
                    Please make sure you upload clear and readable documents
                </p>
            </div>
            <div className="w-full flex flex-col gap-3 md:w-[350px]">
                {
                    policyDetails.map((policy, idx) => {
                        return (
                            <div className="flex flex-col gap-2 md:gap-3 bg-gray-100 rounded-md p-4" key={idx}>
                                <div>
                                    <label className="text-gray-600">Policy Number {idx + 1}</label>
                                    <input
                                        type="text"
                                        id="policy_number"
                                        value={policy?.policy_number}
                                        disabled={policy?.policy_number && policy?.policy_file}
                                        className={`${classNames} border disabled:bg-transparent disabled:border-gray-300 bg-gray-50 text-gray-900 text-sm rounded-lg w-full p-2.5 mt-1`}
                                        placeholder="e.g JH8764598"
                                        onChange={(e) => onChange(idx, e.target.id, e.target.value)}
                                        autoFocus={false}
                                    />
                                </div>
                                <div className="w-full">
                                    <div>
                                        {
                                            !policy?.policy_file
                                                ? <FileUpload
                                                    key={idx}
                                                    uploadPath={fileUploadPath}
                                                    prefix={policy.policy_number}
                                                    title="Upload policy"
                                                    onUpload={(path) => onChange(idx, 'policy_file', path)}
                                                />
                                                : <div className="flex gap-2 items-center justify-between">
                                                    <p className="flex items-center">
                                                        <BsCheckAll size={25} color="green" />
                                                        <span className="text-xs">Policy File Uploaded!</span>
                                                    </p>
                                                    <div
                                                        onClick={() => handleRemoveFile(idx)}
                                                        className="bg-red-500 text-[10px] py-1 px-2 cursor-pointer text-white rounded-md flex align-middle items-center justify-between"
                                                    >
                                                        <p>Delete File</p>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div>
                                        {
                                            policyDetails.length > 1 &&
                                            <div className="flex items-center mt-2">
                                                <div
                                                    onClick={() => handleRemoveField(idx)}
                                                    className="gap-2 bg-slate-200 text-xs py-1 px-2 cursor-pointer text-gray-600 rounded-md flex align-middle items-center justify-between"
                                                >
                                                    <p>Remove Policy</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div
                    onClick={handleAddField}
                    className="p-2 cursor-pointer border-slate-200 rounded-md  border text-sm p-2rounded-md flex align-middle items-center gap-2"
                >
                    <BsPlusCircle />
                    <p className="text-gray-600" >Add another policy</p>
                </div>
            </div>
            {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
        </div>
    )
}
export default PolicyDetailsField;