import React from "react";
import { MdClose } from "react-icons/md";

const SlideOver = ({ title, children, onClose }) => {
    return (
        <div className="relative z-50" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <div className="pointer-events-auto relative w-screen max-w-md">
                            <div className="absolute right-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                <button type="button" className="rounded-md text-gray-500" onClick={onClose}>
                                    <MdClose size={30} />
                                </button>
                            </div>

                            <div className="flex h-full flex-col overflow-y-scroll bg-white pt-6 shadow-xl">
                                <div className="px-4 sm:px-6 pb-4 mb-2 shadow-sm">
                                    <div className="text-xl font-semibold leading-6 text-gray-700" id="slide-over-title">
                                        {title}
                                    </div>
                                </div>
                                <div className="flex-1 overflow-y-scroll">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideOver;