import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ChatMessage from "../QuestionContainer";
import * as Constant from "../../constants";

const ServicePolicyOptions = ({ value = [], required = true, isValid, onValueChange, formData }) => {
    const [error, setError] = useState("");
    const policy_service_options = Constant.SERVICE_EXISTING_POLICY_OPTIONS;

    const validate = (inputValue) => {
        if (inputValue?.length < 1 && required) {
            return "Please select a service!";
        }
        return "";
    }

    const onValueSelect = (e) => {
        const _value = e.target.value;
        const idx = value.indexOf(_value);

        if (idx === -1) {
            const _updatedValues = [...value];

            _updatedValues.push(_value);
            onValueChange(_updatedValues);
            e.target.value = "";

            const errorMsg = validate(_value);
            if (errorMsg) {
                setError(errorMsg);
                isValid(false);
                return;
            }
            isValid(true);
            setError("");
        } else {
            setError("Option is already selected!");
        }
    }

    const removeOption = (valueText) => {
        let _value = [...value]
        const idx = _value.indexOf(valueText);

        if (idx !== -1) {
            _value.splice(idx, 1);
            onValueChange(_value);

            const errorMsg = validate(_value);
            isValid(!!!errorMsg);

            if (errorMsg) {
                setError(errorMsg);
                return;
            }
        }
    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[300px] md:w-[500px] flex flex-col items-center justify-center">
            <ChatMessage message="Which service you are looking for?" isRequired={required} />
            <div className="flex flex-col gap-3 mt-5 text-center">
                <div className="flex items-center align-middle w-full">
                    <select
                        onChange={onValueSelect}
                        className={`${classNames} block text-gray-900 text-sm appearance-none w-full px-4 py-2 pr-8 rounded-md border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                    >
                        <option value="" disabled selected>Select one or more services</option>
                        {policy_service_options.map(_type => (
                            <option key={_type} value={_type}>{_type}</option>
                        ))}
                    </select>
                </div>
                {error && <p className="text-sm text-red-500 my-0.5">{error}</p>}
                {
                    value
                    && <div className="flex flex-wrap gap-2 items-center justify-center">
                        {
                            value.map((_valueText, idx) => {
                                return (
                                    <div
                                        onClick={() => removeOption(_valueText)}
                                        className="flex gap-3 items-center border px-2 py-1 w-fit rounded-2xl group cursor-pointer"
                                        key={idx}

                                    >
                                        <div className="text-xs text-gray-700">{_valueText}</div>
                                        <div>
                                            <AiOutlineCloseCircle size={20} className="text-red-500/80" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}
export default ServicePolicyOptions;