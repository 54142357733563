import banner_2 from "./health insurance desktop img.png";
import banner_2_m from "./health insurance mobile img.png";
import banner_3 from "./life insurance desktop.png";
import banner_3_m from "./life insurance mobile img.png";
import banner_4 from "./general insurance desktop img.png";
import banner_4_m from "./general insurance mobile img.png";

export default [
  {
    desktop: banner_2,
    mobile: banner_2_m,
  },
  {
    desktop: banner_3,
    mobile: banner_3_m,
  },
  {
    desktop: banner_4,
    mobile: banner_4_m,
  },
]
