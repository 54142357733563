import React from 'react';
import { useLocation } from 'react-router-dom';
import { BsFillTelephoneOutboundFill } from "react-icons/bs"
import * as hooks from "../Hooks";

const CallNowButton = () => {
  const location = useLocation();
  const shouldHide = location.pathname.startsWith('/admin')
  const isScrolledToBottom = hooks.useScroll();

  if (shouldHide) return;

  return (
    <a
      href="tel:9130045678"
      className={`fixed ${isScrolledToBottom ? 'bottom-20' : 'bottom-6'} right-4 bg-black rounded-md text-white p-2.5 px-4 text-sm shadow-md hover:bg-gray-800 z-50 w-32`}
    >
      <button className="flex align-middle items-center">
        <BsFillTelephoneOutboundFill className="mr-3" /> Call Now
      </button>
    </a>
  );
};

export default CallNowButton;
