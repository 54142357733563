const send_email_notification = async (subject, html_body) => {
    const apiUrl = 'https://api.brevo.com/v3/smtp/email';
    const apiKey = process.env.REACT_APP_BREVO_API_KEY;

    const data = {
        sender: {
            name: process.env.REACT_APP_SENDER_NAME,
            email: process.env.REACT_APP_SENDER_EMAIL,
        },
        to: [
            {
                email: process.env.REACT_APP_ADMIN_EMAIL,
                name: process.env.REACT_APP_ADMIN_NAME,
            },
        ],
        subject: subject,
        htmlContent: html_body,
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'api-key': apiKey,
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        console.log(result);
    } catch (error) {
        console.error('Error:', error);
    }
}

export default send_email_notification;