import React from "react";
import logo from "../Assets/Images/logo.png"
import GoogleLoginButton from "./GoogleLoginButton";
import { useLocation } from 'react-router-dom';
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";

const Header = () => {
  const location = useLocation();
  const shouldHide = location.pathname.startsWith('/admin')

  return (
    <div className="fixed top-0 left-0 w-full bg-white/95 z-50 shadow-md">
      {
        !shouldHide &&
        <div className="hidden md:flex md:justify-between md:items-center bg-gray-900 text-white h-12">
          <div className="container flex  justify-between items-center mx-auto text-sm">
            <div className="flex items-center gap-14">
              <div className="flex justify-center items-center gap-3 px-3">
                <AiOutlineMail size={20} />
                <p>info@insurebaba.com</p>
              </div>
            </div>
            <div className="flex items-center gap-14">
              <div className="flex justify-center items-center gap-3 px-3">
                <FiPhoneCall size={20} />
                <p className="text-xl">91300 45678</p>
              </div>
            </div>
          </div>
        </div>
      }
      <nav className="bg-primary border-gray-200 px-4 sm:px-8 py-3">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center">
            <img src={logo} className="w-48 md:w-48" alt="logo" />
          </a>
          <div className="text-sm">
            <GoogleLoginButton />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
