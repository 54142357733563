import React from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import { AdminLayout, InstallApp, UserLayout } from "./Components";
import * as Sentry from "@sentry/browser";
import { Toaster } from "react-hot-toast";
import { ScrollToTop, CallNowButton } from "./Components";
import * as Pages from "./Pages";
import * as routes from "./routes";
import * as Context from "./Context";

try {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
} catch (error) {
    console.log("Ignore: Sentry init issue!")
}
ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

function App() {
    return (
        <>
            <Toaster position="top-center" containerClassName="mt-10" />
            <ScrollToTop />
            <CallNowButton />
            <InstallApp/>
            <Context.UserContextProvider>
                <Routes>
                    <Route path={routes.Home} element={<UserLayout />} >
                        <Route path={routes.Home} element={<Pages.Home />} />
                        <Route path={routes.PurchasePolicy} element={<Pages.PurchasePolicy />}></Route>
                        <Route path={routes.RenewPolicy} element={<Pages.ServicePolicy />}></Route>
                        <Route path={routes.OtherServices} element={<Pages.OtherServices />}></Route>
                        <Route path={routes.ClaimPolicy} element={<Pages.ClaimPolicy />}></Route>
                    </Route>
                    <Route path={routes.AdminHome} element={<AdminLayout />}>
                        <Route path={routes.AdminHome} element={<Pages.Admin.Home />}></Route>
                        <Route path={routes.Agents} element={<Pages.Admin.Agents />}></Route>
                    </Route>
                </Routes>
            </Context.UserContextProvider>
        </>
    );
}

export default App;
