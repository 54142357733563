import React from "react";
import { Link } from "react-router-dom";
import * as Avatar from "../Assets/Avatars";
import * as Constants from "../constants";
import * as Components from "../Components";

const Home = () => {
  return (
    <div>
      <section className="w-screen mx-auto bg-green-100/75 max-h-[80vh] overflow-hidden">
        <div className="hidden md:block">
          <Components.HomeCarousel mobile={false} />
        </div>
        <div className="block md:hidden">
          <Components.HomeCarousel mobile={true} />
        </div>
      </section>

      {/* About Us */}
      <section className="bg-orange-100/60 py-12">
        <Components.AboutUs />
        {/* <Components.WhyUs/> */}
      </section>

      {/* Main services section */}
      <section className="container max-w-[85rem] mx-auto px-4 py-10 sm:px-6 lg:px-8 md:py-16 mt-4 md:mt-10">
        {/* <div className="flex flex-col align-middle justify-center">
          <div className="flex align-middle justify-center">
            <img className="w-52" src={Avatar.main} />
          </div>
          <div>
            <div className="mt-5 max-w-4xl text-center mx-auto">
              <h1 className="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                Let's Get
                <span className="bg-clip-text bg-gradient-to-tl from-blue-600 to-violet-600 text-transparent">
                  {" "}
                  Secured
                </span>
              </h1>
              <p className="text-2xl font-bold mt-2 text-gray-700">
                We Serve What You Deserve!
              </p>
            </div>
          </div>
        </div> */}

        <div className="flex justify-center items-center mt-5 md:mt-10">
          <div className="grid grid-cols-1 md:w-full w-[350px] md:grid-cols-2 lg:grid-cols-4 gap-4">
            {Object.values(Constants.MAIN_SERVICES).map((service) => {
              return (
                <Link to={service.link_to} key={service.key}>
                  <div
                    className={`cursor-pointer shadow-md shadow-gray-200/75 bg-white border border-gray-200 rounded-md pb-2 hover:border-gray-600 hover:transition-all hover:duration-700 hover:ease-in ${service.class_name}`}
                    key={service.key}
                  >
                    <div className="bg-white min-h-[530px] rounded-md">
                      <div className="bg-gradient-to-b p-4 rounded-lg flex items-center align-middle justify-between gap-3">
                        <div className="flex flex-col w-full justify-center items-center gap-4">
                          <img
                            src={service.image}
                            title={service.title}
                            className="h-65 object-fit"
                          />
                          <div className="h-32">
                            <h3 className="text-2xl font-bold text-center w-full mb-4">
                              {service.title}
                            </h3>
                            <p className="text-center h-16">
                              {service.description}
                            </p>
                          </div>
                          <div className="text-white bg-gray-800 disabled:bg-gray-500 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-8 py-4 text-center">
                            Click here
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>

      {/* Our Team */}
      <section className="bg-[#f6f7ff] w-screen mx-auto py-12">
        <Components.OurTeam />
      </section>

      {/* Photo Gallery */}
      <section className=" bg-[#192231] ">
        <div className="hidden md:block">
          <Components.GeneralCarousel mobile={false} />
        </div>
        <div className="block md:hidden">
          <Components.GeneralCarousel mobile={true} />
        </div>
      </section>

      {/* Become Agent Section  */}
      <section className="bg-green-100/75 w-screen">
        <div className="container max-w-[85rem] mx-auto p-4 sm:px-6 lg:px-8 md:py-10">
          <Components.BecomeAnAgent />
        </div>
      </section>
    </div>
  );
};

export default Home;
