import React from "react";
import * as Components from "../Components";
import * as FormConfig from "../FormConfig";
import * as avatars from "../Assets/Avatars";
import * as Constants from "../constants";

const PurchasePolicy = () => {
  const formFields = FormConfig.PURCHASE_FORM_CONFIG;
  const service_type = Constants.MAIN_SERVICES_ENUM.POLICY_PURCHASE;

  return (
    <div className="container mx-auto px-2">
      <div className="relative before:absolute before:top-0 before:left-1/2">
        <div className="lg:flex lg:items-center max-w-[85rem] mx-auto px-2 lg:px-8 pt-10 pb-10 min-h-[90vh]">
          <Components.HeadingHero
            part1Title="Consult us"
            part2Title="before buying policy"
            description="Safeguard your future with comprehensive insurance coverage tailored to your needs. Gain peace of mind knowing you're protected against unexpected events. Invest in your security today and secure a worry-free tomorrow."
            colorReverse={true}
            avatar={avatars.financial}
          />
          <Components.ServiceFormContainer
            formFields={formFields}
            service_type={service_type}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchasePolicy;
