import FORM_FIELDS from "../formFields";
import * as Utils from "../Utils";
import DownloadFileButton from "./DownloadFileButton";

const LeadDetails = ({ leadDetails }) => {
    const excludeFields = [
        "id",
        "name",
        "created_at",
        "created_by",
        "assigned_at",
        "status",
        "mobile_no",
        "email",
        "comments",
    ];

    const renderValue = (key, value) => {
        switch (key) {
            case "insurance_type":
                return Utils.getInsuranceTypeDetailsByKey(value).title;

            case "service_type":
                return (
                    <span className="bg-green-100 text-black-800 text-xs font-medium mr-2 px-2 py-1 rounded">
                        {Utils.getServiceTypeDetailsByKey(value).admin_title}
                    </span>
                );

            case "document_file":
            case "policy_file":
                return <DownloadFileButton filePath={value} />;

            case "relation":
                return (
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                        {value}
                    </span>
                );

            case "email":
            case "mobile_no":
                return (
                    <p className="text-blue-700">
                        {value}
                    </p>
                );

            default:
                return value;
        }
    };

    const renderNestedFields = (element, index, key) => {
        return (
            <div key={`${key}-${index}`} className="pl-4 py-2">
                {
                    typeof element === "string"
                        ? renderValue(key, element)
                        : Object.entries(element).map(
                            ([nestedField, nestedValue]) => (
                                <div
                                    key={`${key}-${index}-${nestedField}`}
                                    className="rounded-md py-1"
                                >
                                    <div className="text-black text-sm font-bold">
                                        {FORM_FIELDS[nestedField]?.title}
                                    </div>
                                    <div>
                                        {nestedValue.toString()
                                            ? renderValue(nestedField, nestedValue)
                                            : "N/A"}
                                    </div>
                                </div>
                            )
                        )}
                <div className="py-2"><hr /></div>
            </div>
        );
    };

    const renderLeadDetails = (showBottomLine = false) => {
        return Object.entries(leadDetails).map(([key, value]) => {
            if (!excludeFields.includes(key)) {
                return (
                    <div key={key} className="rounded-md">
                        <div className="text-black font-bold">
                            {FORM_FIELDS[key]?.title}
                        </div>
                        <div>
                            {
                                typeof value === "string"
                                    ? renderValue(key, value)
                                    : value.map((element, index) =>
                                        renderNestedFields(element, index, key)
                                    )
                            }
                        </div>
                    </div>
                );
            }
            return null;
        });
    };

    const singleFieldRender = (label, value) => {
        return (
            <div className="rounded-md">
                <div className="text-black font-bold">{label}</div>
                <div>{value}</div>
            </div>
        )
    }

    return <div className="flex flex-col gap-2">
        {singleFieldRender('Name', leadDetails.name)}
        {singleFieldRender('Mobile Number', leadDetails.mobile_no)}
        {singleFieldRender('Email', leadDetails.email)}
        <hr />
        {renderLeadDetails()}
        {
            leadDetails.comments
            && <><hr /> {singleFieldRender('Team Comments', leadDetails.comments)}</>
        }
    </div>;
};

export default LeadDetails;
