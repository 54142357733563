import React from "react";
import { GoSearch } from "react-icons/go";

const SearchBox = ({ onChange, placeholder, width, className=null, type="text" }) => {
    return (
        <div className={`${className}`}>
            <div className="relative">
                <div className="absolute hidden md:flex inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <GoSearch className='text-gray-500' />
                </div>
                <input
                    type={type}
                    onChange={(e) => onChange(e.target.value)}
                    className="block w-full p-3 md:pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
}
export default SearchBox;