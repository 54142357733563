import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import send_email_notification from "../Libs/brevo";
import { getServiceTypeDetailsByKey } from "../Utils";
import * as Context from "../Context";
import * as Components from "../Components";
import * as insuranceLeadService from "../Services/insuranceLeads";


const ServiceFormContainer = ({ service_type, formFields }) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(Context.UserContext);

    const submitForm = async (formData) => {
        setIsLoading(true);
        formData['service_type'] = service_type;
        formData['created_by'] = user?.email;
        formData['name'] = user?.displayName;
        formData['email'] = user?.email;
        insuranceLeadService.addNewLead(formData).then(async (data) => {
            if (!data.error) {
                const service = getServiceTypeDetailsByKey(formData['service_type'])
                const email_subject = `New inquiry for ${service.admin_title}`;
                const email_body = `
                <html>
                    <head></head>
                    <body>
                        <p>
                            You have received new  inquiry for ${service.admin_title} by <b>${formData['created_by']}</b>. <br>
                            Please visit https://insurebaba.com/admin to see more details.<br/>
                            <br/>
                            Reference Id: ${data?.data?.id}
                        </p>
                    </body>
                </html>
                `;
                await send_email_notification(
                    email_subject,
                    email_body,
                );
                setFormSubmitted(true);
                setIsLoading(false);
            } else {
                toast.error('Something went to wrong!');
                setIsLoading(false);
            }
        });
    }


    return (
        <div className="flex flex-col items-center container mx-auto px-2 mt-5">
            <div className="w-full shadow-lg rounded-lg border-2  border-gray-100 bg-white/40">
                {
                    user ? (
                        <div className="p-4 lg:p-10 flex flex-col justify-center items-center">
                            {
                                !formSubmitted
                                    ? <>
                                        <div className="max-w-[28rem] font-semibold text-gray-800 text-center md:text-xl  pt-2 pb-4">
                                            Kindly provide your information below, and our team will promptly contact you with further details.
                                        </div>
                                        <Components.DynamicForm formFields={formFields} onSubmit={submitForm} isLoading={isLoading}/>
                                    </>
                                    : <Components.FormFields.Success />
                            }
                        </div>
                    ) : <div className="w-full flex justify-center p-4 md:my-20">
                        <Components.LoginPanel />
                    </div>
                }
                <div className="bg-orange-500/70 text-sm text-center p-2 rounded-b-lg">
                    Have any doubts? Call us between 9 AM to 8 PM.
                </div>
            </div>
            <Components.BackToHome textOnly={true} />
        </div>
    )
}

export default ServiceFormContainer;