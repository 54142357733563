import { db } from "../Libs/firebase";
import * as firestore from "firebase/firestore";
import * as Constants from "../constants";

const collectionRef = firestore.collection(db, Constants.FC_AGENT_LEADS);

export const addNewLead = async (data) => {
    try {
        data['created_at'] = firestore.serverTimestamp();
        data['status'] = Constants.AGENT_ENQUIRY_STATUSES.NEW;
        const _data = await firestore.addDoc(collectionRef, data);
        return { data: _data, error: null }
    } catch (error) {
        return { data: null, error: error }
    }
};

export const updateAgent = async (documentId, fields = null) => {
    try {
        if (!fields) return false;
        const documentRef = firestore.doc(db, Constants.FC_AGENT_LEADS, documentId);
        const resp = await firestore.updateDoc(documentRef, fields);
        return true
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false
    }
}

export const deleteAgentById = async (documentId) => {
    try {
        const documentRef = firestore.doc(db, Constants.FC_AGENT_LEADS, documentId);
        await firestore.deleteDoc(documentRef);
        return true
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false
    }
};