import React, { useEffect, useState } from "react";
import ChatMessage from "../QuestionContainer";

const HaveDisease = ({ value="", required = true, isValid, onValueChange,  formData}) => {
    const [error, setError] = useState("");

    const validate = (inputValue) => {
        if (!inputValue && required) {
            return "Please specify your answer!";
        }
        return "";
    }

    const onChange = (e) => {
        const value = e.target.value;
        onValueChange(value);

        const errorMsg = validate(value);
        isValid(!!!errorMsg);

        if (errorMsg) {
            setError(errorMsg);
            return;
        }
        setError("")
        
    }

    useEffect(() => {
        const errorMsg = validate(value);
        setError(errorMsg);
        isValid(!!!errorMsg);
    }, []);

    const classNames = error ? "border-red-500" : "border-gray-300";

    return (
        <div className="w-[300px] md:w-[400px] flex flex-col items-center justify-center">
            <ChatMessage message="Do you have existing disease?" isRequired={required} />
            <div className="text-center mb-3">
                <div className="flex justify-center gap-8 my-3 flex-wrap">
                    <div className="flex items-center align-middle gap-x-3">
                        <input
                            type="radio"
                            id="have_disease"
                            value="Yes"
                            name="have_disease"
                            checked={value === "Yes"}
                            className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block p-4`}
                            onChange={onChange}
                            autoFocus={false}
                        />
                        <p>Yes</p>
                    </div>
                    <div className="flex items-center align-middle gap-x-3">
                        <input
                            type="radio"
                            id="have_disease"
                            value="No"
                            name="have_disease"
                            checked={value === "No"}
                            className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block p-4`}
                            onChange={onChange}
                            autoFocus={false}
                        />
                        <label>No</label>
                    </div>
                    <div className="flex items-center align-middle gap-x-3">
                        <input
                            type="radio"
                            id="have_disease"
                            value="Don't No"
                            name="have_disease"
                            checked={value === "Don't No"}
                            className={`${classNames} border bg-gray-50 text-gray-900 text-sm rounded-lg block p-4`}
                            onChange={onChange}
                            autoFocus={false}
                        />
                        <label>I don't know!</label>
                    </div>
                </div>
                {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    )
}
export default HaveDisease;