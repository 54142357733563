import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import banners from "../Assets/HomeCarousel";

const HomeCarousel = ({ mobile = false }) => {
    return (
        <Carousel
            showArrows={true}
            showIndicators={true}
            showThumbs={false}
            stopOnHover={true}
            swipeable={true}
            useKeyboardArrows={true}
            infiniteLoop={true}
            autoPlay={true}
            showStatus={false}
            dynamicHeight={false}
            preventMovementUntilSwipeScrollTolerance={true}
        >
            {
                banners.map(image => {
                    return <div>
                        {
                            !mobile
                                ? <img src={image.desktop} className="hidden md:block" />
                                : <img src={image.mobile} className="block md:hidden" />
                        }
                    </div>
                })
            }
        </Carousel>
    )
}
export default HomeCarousel;