import * as Constants from "../constants";

const PURCHASE_FORM_CONFIG = [
    {
        field: "insurance_type",
        isRequired: true,
        dependsOn: [],
        isLast: false,
        order: 1,
    },

    // Health Insurance
    {
        field: "family_individual",
        dependsOn: [
            {
                field: "insurance_type",
                value: Constants.INSURANCE_TYPES_ENUM.HEALTH_INSURANCE
            }
        ],
        isRequired: true,
        isLast: false,
        order: 6,
    },
    {
        field: "dob",
        dependsOn: [
            {
                field: "family_individual",
                value: "Individual",
            }
        ],
        isRequired: true,
        isLast: false,
        order: 7,
    },
    {
        field: "have_disease",
        dependsOn: [
            {
                field: "family_individual",
                value: "Individual",
            }
        ],
        isRequired: true,
        isLast: false,
        order: 8,
    },
    {
        field: "risk_cover",
        dependsOn: [
            {
                field: "family_individual",
                value: "Individual",
            }
        ],
        isRequired: true,
        isLast: false,
        order: 9,
    },
    {
        field: "members_count",
        dependsOn: [
            {
                field: "family_individual",
                value: "Family Cover",
            }
        ],
        isRequired: true,
        isLast: false,
        order: 10,
    },
    {
        field: "member_details",
        dependsOn: [
            {
                field: "family_individual",
                value: "Family Cover",
            }
        ],
        isRequired: false,
        isLast: false,
        order: 11,
    },

    // Life insurance
    {
        field: "life_insurance_type",
        dependsOn: [
            {
                field: "insurance_type",
                value: Constants.INSURANCE_TYPES_ENUM.LIFE_INSURANCE,
            }
        ],
        isRequired: true,
        isLast: false,
        order: 12,
    },

    // Other
    {
        field: "general_insurance_type",
        dependsOn: [
            {
                field: "insurance_type",
                value: Constants.INSURANCE_TYPES_ENUM.GENERAL_INSURANCE,
            }
        ],
        isRequired: true,
        isLast: false,
        order: 13,
    },
    {
        field: "related_documents",
        dependsOn: [
            {
                field: "general_insurance_type",
                value: Constants.GENERAL_INSURANCE_TYPES[0] // Business
            }
        ],
        isRequired: false,
        isLast: false,
        order: 14,
    },
    {
        field: "related_documents",
        dependsOn: [
            {
                field: "general_insurance_type",
                value: Constants.GENERAL_INSURANCE_TYPES[1] // Vehicle
            }
        ],
        isRequired: false,
        isLast: false,
        order: 14,
    },
    {
        field: "business_insurance_type",
        dependsOn: [
            {
                field: "general_insurance_type",
                value: Constants.GENERAL_INSURANCE_TYPES[0] // Business
            }
        ],
        isRequired: true,
        isLast: false,
        order: 15,
    },
    {
        field: "text_input",
        dependsOn: [
            {
                field: "general_insurance_type",
                value: Constants.GENERAL_INSURANCE_TYPES[2] // Other
            }
        ],
        isRequired: true,
        isLast: false,
        order: 16,
    },
    {
        field: "mobile_no",
        dependsOn: [],
        isRequired: true,
        isLast: true,
        order: 17,
    }
    /**
     * Todo: Bug - gotoNextStep fails if last step is depends on other fields 
     * but value of others fields is not defined
     */
    // {
    //     field: "email",
    //     dependsOn: [],
    //     isRequired: false,
    //     isLast: true,
    //     order: 14,
    // }
]

export default PURCHASE_FORM_CONFIG;